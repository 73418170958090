import cookie from '@/utils/cookie'

const user = {
    state: {
        token: cookie.getCookie('token') || '',
        lastUsername: cookie.getCookie('lastUsername') || '',
        lastPassword: cookie.getCookie('lastPassword') || '',
        username: cookie.getCookie('username') || '',
        password: cookie.getCookie('password') || '',
        userId: cookie.getCookie('userId') || -1,
        isLogin: cookie.getCookie('isLogin') || false,
        needLogin: false,
        email: '',
    },
    mutations: {
        setUserId(state, value) {
            state.userId = value
            cookie.setCookie('userId', state.userId)
        },
        setToken(state, value) {
            state.token = value
            cookie.setCookie('token', state.token)
        },
        setUserName(state, value) {
            state.username = value
            cookie.setCookie('username', state.username)
        },
        setPassword(state, value) {
            state.password = value
            cookie.setCookie('password', state.password)
        },
        setLoginState(state, value) {
            state.isLogin = value
            cookie.setCookie('isLogin', state.isLogin)
        },
        setNeedLogin(state, value) {
            state.needLogin = value;
        },
        setLastUserName(state, value) {
            state.lastUsername = value
            cookie.setCookie('lastUsername', state.lastUsername)
        },
        setLastPassword(state, value) {
            state.lastPassword = value
            cookie.setCookie('lastPassword', state.lastPassword)
        },
        setEmail(state, value) {
            state.email = value
        },
    },
    actions: {
        
        login(state, info) {
            state.commit('setUserId', info.uid)
            state.commit('setToken', info.token)
            state.commit('setUserName', info.username)
            state.commit('setPassword', info.password)
            state.commit('setLastUserName', info.username)
            state.commit('setLastPassword', info.password)
            state.commit('setLoginState', true)
        },
        
        logout(state) {
            state.commit('setUserId', -1)
            state.commit('setToken', "")
            state.commit('setUserName', "")
            state.commit('setPassword', "")
            state.commit('setLoginState', false)
        },

        updateInfo(state, info) {
            state.commit('setEmail', info.email)
        }
    }
}

export default user;
const getters = {
    // app
    appId:state => state.app.appId,
    channel: state => state.app.channel,
    lang: state => state.app.lang,
    deviceId: state => state.app.deviceId,
    os:state => state.app.os,

    // user
    token: state => state.user.token,
    username: state => state.user.username,
    password: state => state.user.password,
    lastUsername: state => state.user.lastUsername,
    lastPassword: state => state.user.lastPassword,
    userId: state => state.user.userId,
    isLogin: state => state.user.isLogin,
    needLogin: state => state.user.needLogin,
    email: state => state.user.email,
}
export default getters
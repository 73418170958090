import axios from 'axios'
import { Toast } from 'vant'
import store from "@/store";

const http = axios.create( {
    baseURL: "https://www.handzone.xyz/api",
    // baseURL: "http://192.168.0.205/api",
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    }
})

http.interceptors.request.use(function(config){
    const token = store.getters.token || '';
    token && (config.headers.Authorization = token);
    const locale = store.getters.lang || 'zh_CN'; 
    config.url = config.url + "?locale=" + locale;
    return config
})

http.interceptors.response.use(function (response) {
  if (response.data.code != 0) {
      if (response.data.msg) {
          if (response.config.url.indexOf("device") < 0) {
              Toast.fail(response.data.msg)
          }
      }
      return Promise.reject(response.data)
  }
  return response.data;
}, function (error) {
    console.log(error)
    // 对响应错误做点什么
    if (error.response.status === 401) {
        console.log("未登录")
        return
    }
    return;
});

export default http
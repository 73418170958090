import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/",
        name: "game",
        component: () => import("../views/Game"),
    },
    {
        path: "/my",
        name: "my",
        component: () => import("../views/user/My"),
    },
    {
        path: "/user/bindemail",
        name: "bindEmail",
        component: () => import("../views/user/BindEmail"),
    },
    {
        path: "/gameplay/:id",
        name: "gameplay",
        component: () => import("../views/GamePlay"),
    },
    {
        path: "/user/password",
        name: "password",
        component: () => import("../views/user/Password"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;

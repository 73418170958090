import axios from '@/utils/axios'

export function requestLogin(params) {
    return axios.post('/login', params)
}

export function requestRegister(params) {
    return axios.post('/register', params)
}

export function requestInfo() {
    return axios.post('/userinfo', {})
}

export function requestEmailVerifycode(params) {
    return axios.post('/verifycode', params)
}

export function requestBindEmail(params) {
    return axios.post('/bindemail', params)
}

export function reqestDevice(params) {
    return axios.post('/device', params)
}

export function reqestPassword(params) {
    return axios.post('/password', params)
}


import { setStore, getStore } from '@/utils/store'

const app = {
    state: {
        deviceId: getStore({ name: 'deviceId' }) || '',
        channel: 3,     // 渠道
        lang:"zh_TW",
        appId: 1040,
        os:"html5"
    },
    mutations: {
        setDeviceId(state, value) {
            state.deviceId = value
            setStore({ name: 'deviceId', content: state.deviceId })
        },
        setChannel(state, value) {
            state.channel = value
        },
        setLang(state, value) {
            state.lang = value
        }
    },
    actions: {
        
    }
}

export default app;
<template>
     <div>
        <div id="login" class="bg" v-if="!showRegisWin">
            <!-- 登录弹窗 -->
            <div class="login-box">
                <div class="title">
                    <img src="/img/login/logo.png" />
                    <span>登入帳號</span>
                </div>
                <div class="input-box">
                    <ul>
                      <li>
                          <span class="user"></span>
                          <input v-model="username" type="text" placeholder="請輸入帳號">
                      </li>
                      <div class="line"></div>
                      <li>
                            <span class="pwd"></span>
                            <input v-model="password" type="password" placeholder="請輸入密碼" maxlength="22">
                            <!-- <div id="pwdeye">
                              <i class="eye"></i>
                            </div> -->
                      </li>
                    </ul>
                </div>
                <div class="login-bottom">
                    <a class="text1" @click="showRegis">註冊账号</a>
                    <a class="text2" @click="forgetShow=true">找回密碼</a>
                </div>
                <div class="btn-box">
                    <a class="btn-login btn" @click="handlerLogin">登錄账号</a>
                </div>
            </div>
        </div>

        <div id="register" class="bg" v-else>
            <!-- 注册弹窗 -->
            <div class="register-box">
                <div class="title">
                    <img src="/img/login/logo.png" />
                    <span>註冊賬號</span></div>
                <div class="input-box">
                    <ul>
                    <li >
                        <span class="user"></span>
                        <input v-model="regusername" type="text" placeholder="請輸入帳號" class="form-input">
                    </li>
                      <div class="line"></div>
                    <li>
                        <span class="pwd"></span>
                        <input v-model="regpassword" type="text" placeholder="請輸入密碼" maxlength="15" class="form-input">
                    </li>
                    </ul>
                </div>

                <div class="agree">
                    <van-checkbox v-model="checked" checked-color="#6387ea">同意<a class="abottom" @click="handlerPrivate">《用戶服務協議》</a>
                    <span>和</span>
                    <a class="abottom" @click="handlerPrivate">《隱私政策》</a></van-checkbox>
                </div>

                <div class="btn-box btn-box2">
                  <a href="javascript:;" class="btn-regis2 btn" @click="handlerRegister">立即註冊</a>
                  <a href="javascript:;" class="yiyoutext1" @click="showLogin">已有帳號</a>
                </div>
            </div>
        </div>
        
        <!-- 隐私政策弹窗 -->
        <van-popup v-model:show="forgetShow" round :style="{ height: '90%', width: '90%', maxWidth: '440px' }">
            <iframe :src="forgetUrl" frameborder="0"/>
        </van-popup>
        <!-- 找回密碼弹窗 -->
        <van-popup v-model:show="priveShow" round :style="{ height: '90%', width: '90%', maxWidth: '440px' }">
            <iframe :src="privateUrl" frameborder="0"/>
        </van-popup>
        
     </div>
</template>

<script>
import { reactive, onMounted, toRefs, nextTick } from 'vue'
import { useStore } from 'vuex'

import { requestLogin, requestRegister } from "@/service/user";
import { Toast } from "vant";
import * as system from '@/utils/system'
import { validatenull } from '@/utils/validate';
import { useRouter } from "vue-router";

export default {
    setup() {
        const store = useStore()
        const router = useRouter();

        const state = reactive({
            priveShow:false,
            forgetShow:false,
            
            username:"",
            password:"",
            regusername:"",
            regpassword:"",
            checked:true,
            showRegisWin:false,
            forgetUrl:"",
            privateUrl:""
        })

        onMounted(() => {
            store.commit('setLoginState', false)

            let username = store.getters.lastUsername
            let password = store.getters.lastPassword;
            
            if (validatenull(username) || validatenull(password)) {
                showRegis()
            } else {
                state.username = username
                state.password = password
            }

            state.forgetUrl = "http://www.handzone.xyz/handzone/usercenter/findpassword.html?lang=" + store.getters.lang + "&gameId=" + store.getters.appId
            state.privateUrl = "http://www.handzone.xyz/handzone/privacy/index.html?lang=" + store.getters.lang + "&gameId=" + store.getters.appId
        });

        const showRegis = () => {
            state.showRegisWin = true;
            state.regusername = randomWord(1, 1) + Date.parse(new Date()) / 1000 + randomWord(1, 1);
            state.regpassword = randomWord(10, 10);
        }

        const showLogin = () => {
            state.showRegisWin = false;
        }

        const randomWord = (min, max) => {
            let str = "";
            let range = min;
            let arr = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
            range = Math.round(Math.random() * (max - min)) + min;
            for (var i = 0; i < range; i++) {
                let pos = Math.round(Math.random() * (arr.length - 1));
                str += arr[pos];
            }
            return str;
        }

        const handlerLogin = async () => {
            if (validatenull(state.username) || validatenull(state.password)) {
                Toast("請輸入賬號密碼")
                return;
            }
            Toast.loading({ message:"登錄中", duration: 0, forbidClick: true});
            const result = await requestLogin({
                gameId: store.getters.appId,
                username: state.username,
                password: state.password,
                os: store.getters.os,
                osVersion: system.GetOs(),
                model: system.GetCurrentBrowser(),
                ssid: "",
                channel: store.getters.channel,
                locale: store.getters.lang
            });
            result.password = state.password;
            Toast.success("登錄成功")
            console.log("登錄成功")
            store.dispatch("login", result)
            // router.go(0)

            console.log(router)

            nextTick(() => {
                var event = document.createEvent("HTMLEvents");
                event.initEvent("loginSuccess", true, true);
                window.dispatchEvent(event);
            })
        }

        const handlerRegister = async ()=> {
            if (!state.checked) {
                Toast("請先閱讀並同意協議")
                return
            }
            if (validatenull(state.regusername) || validatenull(state.regpassword)) {
                Toast("請輸入賬號密碼")
                return;
            }
            if (state.regusername.length < 6 || state.regusername.length > 18) {
                Toast("賬號長度為6-18位字符")
                return;
            }
            if (state.regpassword.length < 6 || state.regpassword.length > 18) {
                Toast("密碼長度為6-18位字符")
                return;
            }
            Toast.loading({ message:"註冊中", duration: 0, forbidClick: true});
            await requestRegister({
                username: state.regusername,
                password: state.regpassword,
                passwordConfirmation: state.regpassword,
                deviceId: store.getters.deviceId
            });
            state.username = state.regusername
            state.password = state.regpassword
            Toast.success("註冊成功")
            handlerLogin()
        }

        const handlerPrivate = () => {
            state.priveShow = true
        }

        return {
          ...toRefs(state),
          handlerLogin,
          handlerRegister,
          showLogin,
          showRegis,
          handlerPrivate
        }
    },
    components: {
    }
}
</script>

<style scoped lang="less">
.bg {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    background-color:rgba(0,0,0,0.5);
    position:absolute;
    left:0%;
    top:0%;
    z-index: 1001;
}

iframe {
    height: 100%;
    width: 100%;
}

.input-box ul li span { position: absolute; left: 0; width: 40px; height: 26px; display: flex;}
.input-box ul li span.user {  background: url(/img/login/user.png) no-repeat center; background-size: 24px auto;}
.input-box ul li span.pwd { background: url(/img/login/pwd.png) no-repeat center; background-size: 18px auto;}
.eye { position: absolute; background: url(/img/login/eye.png) no-repeat center; background-size: 24px auto; right: 0; width: 40px; height: 26px;}
.eye-slash { position: absolute; background: url(/img/login/eye-slash.png) no-repeat center; background-size: 24px auto; right: 0; width: 40px; height: 26px;}

.arrow {position: absolute;left:0;width: 60px; height:55px; background: url(/img/login//arrow.png) no-repeat center; background-size: 28px auto;cursor:pointer;}

.login-box {
    position: absolute;
    width: 90vw;
    max-width: 500px;
    background-color: #ffffff;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50%);
    padding-bottom: 20px;
}

.title {
    background-color: #ffffff;
    width:100%;
    height: 55px;
    border-radius: 10px 10px 0px 0px;
    text-align: center;
    color: #414141;
    font-size: 20px;
    line-height: 55px;
    border-bottom: 1px solid #e5e5e5;
}

.title img {
    width: 0.9rem;
    position: absolute;
    left: 0.3rem;
    top: 0.3rem;
}

.input-box {
    background-color: #ffffff;
    width:92%;
    height: 110px;
    margin-left: 4%;
    margin-top: 20px;
    border-radius: 10px;
}

.input-box ul {
    position: relative;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    margin-top: 0.3rem;
}

.input-box ul li{
  position: relative;
  clear: both; 
  margin-top: 15px; 
  height: 26px; 
  margin-bottom: 15px; 
}

.input-box ul li input {
  float: left;
  background: none;
  border: 0;
  width: 100%;
  color: #8c8c8c;
  font-size: 16px;
  margin-left: 40px;
  margin-top: 3px;
}

.line {
  border-top: 1px solid #ddd;
  margin-top: 13px;
}

a {
    text-decoration: none;
    cursor:pointer;
    color: #f3d8a9;
}
.abottom {
  border-bottom:solid #f3d8a9 1px;
}

.btn-box {
  width:92%;
  margin-left: 4%;
  margin-top: 30px;
  border-radius: 8px;
}

.btn-regis {
    display: flex;
    width: 40%;
    height: 50px;
    background-color: #6e87ea;
    border-radius: 8px;
    font-size: 18px;
    color: #fff;
    justify-content: center;
    align-items: center;
    float: left;
}

.btn-login {
    display: flex;
    width: 100%;
    height: 50px;
    background-color: #333333;
    border-radius: 8px;
    font-size: 18px;
    color: #f3d8a9;
    justify-content: center;
    align-items: center;
}

.login-bottom {
    margin-top: 20px;
    border-radius: 8px;
    font-size: 18px;
    margin-bottom: 60px;
}

.login-bottom .text1{
    display: flex;
    float: left;
    margin-left: 18px;
}

.yiyoutext1 {
    font-size: 18px;
    display: flex;
    margin-top: 15px;
    justify-content: center;
}

.login-bottom .text2{
    display: flex;
    float: right;
    margin-right: 18px;
}


.register-box {
    position: absolute;
    width: 90vw;
    max-width: 500px;
    background-color: #ffffff;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50%);
    padding-bottom: 20px;
}

.btn-regis2 {
    display: flex;
    width: 100%;
    height: 50px;
    background-color: #333333;
    border-radius: 8px;
    font-size: 18px;
    color: #f3d8a9;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}


.btn-login2 {
    margin-top: 15px;
    display: flex;
    width: 100%;
    height: 50px;
    background-color: #6e87ea;
    border-radius: 8px;
    font-size: 18px;
    color: #fff;
    justify-content: center;
    align-items: center;
}

.agree {
  float: right;
  padding-top: 25px;
  padding-bottom: 15px;
  margin-right: 10px;
  font-size: 16px;
}


</style>
